// src/App.js
import React from 'react';
import LoginForm from './components/LoginForm';
import LoginIdForm from './components/LoginIdForm';
import LoginPasswordForm from './components/LoginPasswordForm';
import SignupForm from './components/SignupForm';
import { getScreenComponent } from './utils/ScreenMapper';
import './App.css'; // Import custom styles

const App = () => {
  const data = window.universal_login_transaction_data;

  const componentMap = {
    login: LoginForm,
    'login-id': LoginIdForm,
    'login-password': LoginPasswordForm,
     signup: SignupForm,
    'signup-password': SignupForm,
    // Add other mappings as necessary
  };

  const screenKey = getScreenComponent(data.prompt.name, data.screen.name);
  const ScreenComponent = componentMap[screenKey] || null;

  return (
    <div className="app-container">
      <div className="background-blur"></div> {/* Background blur layer */}
      <header className="app-header">
        <img src="https://files.desmaximus.com/logo-davivienda-white.png" alt="Logo" className="logo" />
      </header>
      <main className="app-content">
        {ScreenComponent ? (
          <div className="form-background">
            <ScreenComponent state={data.state} />
          </div>
        ) : (
          <p className="text-center text-gray-600">Screen not found</p>
        )}
      </main>
      <footer className="app-footer">
        <a href="/terms" className="footer-link">Terms</a>
        <a href="/privacy" className="footer-link">Privacy</a>
      </footer>
    </div>
  );
};

export default App;

