// src/components/LoginPasswordForm.js
import React, { useState, useEffect, useRef } from 'react';
import './LoginpasswordForm.css';
import JSEncrypt from 'jsencrypt';

const LoginPasswordForm = ({ state }) => {
  const [password, setPassword] = useState('');
  const [obfuscatedUserName, setObfuscatedUserName] = useState('');
  const [typeOfDocumentName, setTypeOfDocumentName] = useState('');
  const formRef = useRef(null);
  // Retrieve the obfuscated username from localStorage when the component mounts
  useEffect(() => {
    const storedObfuscatedUserName = localStorage.getItem('obfuscatedUsername');
    if (storedObfuscatedUserName) {
      setObfuscatedUserName(storedObfuscatedUserName);
    }
    const docTypeName = localStorage.getItem('typeOfDocumentName');
    if(docTypeName){
        setTypeOfDocumentName(docTypeName);
    }
  }, []);

  // Assuming these are provided from somewhere or used as placeholders
  const username = window.universal_login_transaction_data.unsafe_data.submitted_form_data.username;
  const cancelUrl = window.universal_login_transaction_data.links.edit_email;

  const handleSubmit = (e) => {
    e.preventDefault();

    // Encrypt the username
    const encrypt = new JSEncrypt();
    const publicKey = `-----BEGIN PUBLIC KEY-----
    MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAcs62gPomrizuJ2ZtRv+7nGdvjT8neb1k
    fTFMWqOjvcQs8kGTqhG2VvSzNAX7apffgeplhjzZWVG1MPHrjP5dSwIDAQAB
    -----END PUBLIC KEY-----`;

    encrypt.setPublicKey(publicKey);
    const encryptedPasswordvalue = encrypt.encrypt(password);
    setPassword(encryptedPasswordvalue);

    // Set the hidden input's value before submitting the form
    formRef.current.querySelector('input[name="password"]').value = encryptedPasswordvalue;
    // Submit the form
    formRef.current.submit();
  };

  return (
    <div className="login-id-form-container">
      <h2 className="form-heading">Por favor ingrese la siguiente información:</h2>
      <form method="POST" onSubmit={handleSubmit} ref={formRef} className="inline-form">
        {/* Hidden state and username inputs */}
        <input type="hidden" name="state" value={state} />
        <input type="hidden" name="username" value={username} />

        {/* Non-editable obfuscated username field */}

        <div className="form-group">
          <label className="text-gray-700 font-medium">Tipo documento</label>
          <input
            type="text"
            name="ulp-typeOfDocument"
            value={typeOfDocumentName}
            readOnly
            className="px-2 py-1 border rounded-md bg-gray-100 cursor-not-allowed focus:outline-none"
          />
        </div>

        <div className="form-group">
          <label className="text-gray-700 font-medium">No.documento</label>
          <input
            type="text"
            name="ulp-obfuscatedUserName"
            value={obfuscatedUserName}
            readOnly
            className="px-2 py-1 border rounded-md bg-gray-100 cursor-not-allowed focus:outline-none"
          />
        </div>

        {/* Password field */}
        <div className="form-group">
          <label className="text-gray-700 font-medium">Clave virtual</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="px-2 py-1 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
            required
          />
        </div>

        {/* Submit and Cancel buttons */}
        <div className="form-group">
        <label className="text-gray-700 font-medium">&nbsp;</label>

          <button
            type="submit"
            className="py-1 px-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300"
            name="action"
            value="login"
            style={{ marginTop: '0px'}}
          >
            Login
          </button>
          </div>
          <div className="form-group">
        <label className="text-gray-700 font-medium">&nbsp;</label>
          <a
            href={cancelUrl}
            className="py-1 px-2 bg-gray-600 text-white font-semibold rounded-md hover:bg-gray-700 transition duration-300"
            style={{ marginTop: '0px'}}
          >
            Cancelar
          </a>
          </div>
        
      </form>
    </div>
  );
};

export default LoginPasswordForm;
