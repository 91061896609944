// src/components/SignupForm.js
import React from 'react';

const SignupForm = ({ state }) => {
  return (
    <form method="POST" className="bg-white p-8 rounded-lg shadow-md space-y-6">
      <input type="hidden" name="state" value={state} />
      <div className="space-y-2">
        <label className="block text-gray-700 font-medium">Email:</label>
        <input type="email" name="email" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600" />
      </div>
      <div className="space-y-2">
        <label className="block text-gray-700 font-medium">Password:</label>
        <input type="password" name="password" className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600" />
      </div>
      <button type="submit" className="w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300">Sign Up</button>
    </form>
  );
};

export default SignupForm;
