// src/components/LoginIdForm.js
import React, { useState, useEffect, useRef } from 'react';
import JSEncrypt from 'jsencrypt';

const LoginIdForm = ({ state }) => {
  const [documentType, setDocumentType] = useState('ID');
  const [documentNumber, setDocumentNumber] = useState('');
  const [username, setUsername] = useState('');
  const [obfuscatedUsername, setObfuscatedUsername] = useState('');
  const [encryptedUsername, setEncryptedUsername] = useState('');
  const [documentTypeName, setDocumentTypeName] = useState('Cedula de Ciudadania'); // Initial name

  // Reference to the form element
  const formRef = useRef(null);

  // Update the username and obfuscation whenever documentType or documentNumber changes
  useEffect(() => {
    const newUsername = `${documentType}.${documentNumber}`;
    setUsername(newUsername);
    setObfuscatedUsername(obfuscateUsername(newUsername));
  }, [documentType, documentNumber]);

  // Obfuscate the username function
  const obfuscateUsername = (username) => {
    if (!username) return '';
    const visibleLength = 3; // Number of characters to show at the start
    if(username.split(".")[1].length > 3) {
    const obfuscatedPart = '*'.repeat(username.split(".")[1].length - visibleLength);
    return username.split(".")[1].substring(0, visibleLength) + obfuscatedPart;
    }
  };

  const handleDocumentTypeChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setDocumentType(selectedOption.value);
    setDocumentTypeName(selectedOption.text);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Encrypt the username
    const encrypt = new JSEncrypt();
    const publicKey = `-----BEGIN PUBLIC KEY-----
    MFswDQYJKoZIhvcNAQEBBQADSgAwRwJAcs62gPomrizuJ2ZtRv+7nGdvjT8neb1k
    fTFMWqOjvcQs8kGTqhG2VvSzNAX7apffgeplhjzZWVG1MPHrjP5dSwIDAQAB
    -----END PUBLIC KEY-----`;

    encrypt.setPublicKey(publicKey);
    const encryptedUsernameValue = encrypt.encrypt(username);
    console.log(encryptedUsernameValue);

    setEncryptedUsername(encryptedUsernameValue);

    // Set the hidden input's value before submitting the form
    formRef.current.querySelector('input[name="username"]').value = encryptedUsernameValue;
    localStorage.setItem("obfuscatedUsername",obfuscatedUsername);
    localStorage.setItem("typeOfDocumentName",documentTypeName);

    // Submit the form
    formRef.current.submit();
  };

  return (
    <div className="login-id-form-container">
      <h2 className="form-heading">Por favor ingrese la siguiente información:</h2>
      <form
        method="POST"
        className="inline-form"
        onSubmit={handleSubmit}
        ref={formRef}
      >
        <input type="hidden" name="state" value={state} />
        <input type="hidden" name="username" value={encryptedUsername} />
        <input type="hidden" name="ulp-obfuscatedUserName" value={obfuscatedUsername} />

        {/* Document Type Dropdown */}
        <div className="form-group">
          <label className="text-gray-700 font-medium">Tipo de documento:</label>
          <select
            name="ulp-documentType"
            value={documentType}
            onChange={handleDocumentTypeChange}
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="01" selected="selected">Cedula de Ciudadania</option>
            <option value="02">Cedula de Extranjeria</option>
            <option value="03">NIT</option>
            <option value="04">Tarjeta de Identidad</option>
            <option value="05">Pasaporte</option>
            <option value="06">Trj. Seguro Social Extranjero</option>
            <option value="07">Sociedad Extranjera sin NIT en Colombia</option>
            <option value="08">Fideicomiso</option>
            <option value="09">NIT Menores</option>
            <option value="10">RIF Venezuela</option>
            <option value="11">NIT Extranjeria</option>
            <option value="12">NIT Persona Natural</option>
            <option value="13">Registro Civil De Nacimiento</option>
            <option value="99">NIT Desasociado</option>
            <option value="102">CIF(Numero Unico de Cliente)</option>
            <option value="103">Numero de Identidad</option>
            <option value="104">RTN</option>
            <option value="100">Cedula de Identidad</option>
            <option value="101">DIMEX</option>
            <option value="105">CED</option>
            <option value="106">PAS</option>
            <option value="107">Documento Unico de Identidad</option>
            <option value="108">NIT Salvadoreño</option>
            {/* Add more document types as needed */}
          </select>
        </div>

        {/* Document Number Input */}
        <div className="form-group">
          <label className="text-gray-700 font-medium">No. de documento:</label>
          <input
            type="text"
            name="ulp-documentNumber"
            required
            value={documentNumber}
            onChange={(e) => setDocumentNumber(e.target.value)}
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>

        {/* Submit Button */}
        <div className="form-group">
        <label className="text-gray-700 font-medium">&nbsp;</label>
          <button
            type="submit"
            className="py-2 px-4 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300"
            name="action"
            value="default"
            style={{ marginTop: '0px'}}
          >
            Continuar
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginIdForm;
