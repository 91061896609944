// src/components/LoginForm.js
import React, { useState, useEffect } from 'react';

const LoginForm = ({ state }) => {
  const [documentType, setDocumentType] = useState('ID');
  const [documentNumber, setDocumentNumber] = useState('');
  const [username, setUsername] = useState('');

  // Update the username whenever documentType or documentNumber changes
  useEffect(() => {
    setUsername(`${documentType}.${documentNumber}`);
  }, [documentType, documentNumber]);

  return (
    <form method="POST" className="bg-white p-8 rounded-lg shadow-md space-y-6">
      <input type="hidden" name="state" value={state} />
      <input type="hidden" name="username" value={username} />
      
      <div className="flex space-x-4">
        {/* Document Type Dropdown */}
        <div className="w-1/3">
          <label className="block text-gray-700 font-medium">Document Type:</label>
          <select
            name="documentType"
            value={documentType}
            onChange={(e) => setDocumentType(e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="ID">ID</option>
            <option value="Passport">Passport</option>
            <option value="DriverLicense">Driver License</option>
            {/* Add more document types as needed */}
          </select>
        </div>
        
        {/* Document Number Input */}
        <div className="w-1/3">
          <label className="block text-gray-700 font-medium">Document Number:</label>
          <input
            type="text"
            name="documentNumber"
            value={documentNumber}
            onChange={(e) => setDocumentNumber(e.target.value)}
            onKeyUp={(e) => setDocumentNumber(e.target.value)}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>

        {/* Password Input */}
        <div className="w-1/3">
          <label className="block text-gray-700 font-medium">Password:</label>
          <input
            type="password"
            name="password"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          />
        </div>
      </div>

      <button type="submit" className="w-full py-2 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300">
        Login
      </button>
    </form>
  );
};

export default LoginForm;
