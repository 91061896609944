// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

// Create a new div element
const rootElement = document.createElement('div');
// Assign an id to the new div (optional)
rootElement.id = 'root';
// Append the new div to the body
document.body.appendChild(rootElement);

// Render the React app into the newly created div
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootElement
);
