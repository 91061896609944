// src/utils/ScreenMapper.js
const screenMapping = {
    signup: ['signup'],
    'signup-id': ['signup-id'],
    'signup-password': ['signup-password'],
    login: ['login'],
    'login-id': ['login-id'],
    'login-password': ['login-password'],
  };
  
  export const getScreenComponent = (prompt, screen) => {
    for (const [key, values] of Object.entries(screenMapping)) {
      if (prompt === key && values.includes(screen)) {
        return key;
      }
    }
    return null;
  };
  